<template>
  <div class="container row mt-4" v-if="showSchools">
    <div class="card" style="border: 3px solid green; ">
      <div class="card-header">
        <h3 style="opacity: 1; color: black;">Keeping</h3>
      </div>
      <div class="card-body">
        <MergeObjectDisplay
            :display-data="keepingSchoolDisplay"
            @valChanged="updateSubmitObject($event)"
            :index-id-value="1"
            :radio-checked="true"/>
        <!--        Users and schools-->

      </div>
      <div class="card-body" style="border: 1px solid black; overflow-y: scroll; max-height: 125px; margin: 10px">

        <h5 style="opacity: 1; color: black;">Users in School</h5>
        <div>
          <h6 style="opacity: 1; color: black;">Supervisor</h6>
          <UserHierarchyList :id-organization="idKeepingSchool" :role-hierarchy="4" :org-type="3"/>
        </div>
        <div>
          <h6 style="opacity: 1; color: black;">Students (Para Cours)</h6>
          <UserHierarchyList :id-organization="idKeepingSchool" :role-hierarchy="5" :org-type="3"/>
        </div>
        <div>
          <h6 style="opacity: 1; color: black;">Students (Supervisor Course)</h6>
          <UserHierarchyList :id-organization="idKeepingSchool" :role-hierarchy="6" :org-type="3"/>
        </div>
      </div>
    </div>
    <div class="card" style="border: 3px solid red;">
      <div class="card-header">
        <h3 style="opacity: 1; color: black;">Removing</h3>
      </div>
      <div class="card-body">
        <MergeObjectDisplay
            :display-data="removingSchoolDisplay"
            @valChanged="updateSubmitObject($event)"
            :index-id-value="2"
            :radio-checked="false"/>
      </div>
      <div class="card-body" style="border: 1px solid black; overflow-y: scroll; max-height: 125px; margin: 10px">


      <h5 style="opacity: 1; color: black;">Users to be Merged</h5>
        <div>
          <h6 style="opacity: 1; color: black;">Supervisor</h6>
          <UserHierarchyList :id-organization="idRemovingSchool" :role-hierarchy="4" :org-type="3"/>
        </div>
        <div style="opacity: 1; color: black;">
          <h6 style="opacity: 1; color: black;">Students (Para Course)</h6>
          <UserHierarchyList :id-organization="idRemovingSchool" :role-hierarchy="5" :org-type="3"/>
        </div>
        <div style="opacity: 1; color: black;">
          <h6 style="opacity: 1; color: black;">Students (Supervisor Course)</h6>
          <UserHierarchyList :id-organization="idRemovingSchool" :role-hierarchy="6" :org-type="3"/>
        </div>
      </div>
    </div>
    <MergeResultPreview
        :display-keys="keys"
        :ignore-keys="keysToIgnore"
        :preview-display="previewDisplay"
        @mergeClicked="showMergeModal = true"
    />
    <div v-if="showMergeModal">
      <Teleport to="body">
        <OrgMergeModal
            :org-data="previewDisplay"
            :org-type="`School`"
            :show="showMergeModal"
            @close="showMergeModal = false"
            @mergeCall="mergeHelper"/>
      </Teleport>
    </div>

  </div>
</template>

<script>
import {ref, onBeforeMount, computed, watch} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {getSchool, mergeSchools} from "@/Helpers/ApiCalls/SchoolAPICalls";
import {prepareMergeKeys, prepareMergeObject} from "@/Helpers/MergeObjectHelper";
import Swal from "sweetalert2";
import router from "@/router";
import {isEmpty} from "lodash/lang";
import MergeObjectDisplay from "@/components/Merge/MergeObjectDisplay.vue";
import UserHierarchyList from "@/components/UserOrganizationHierarchy/UserHierarchyList.vue";
import MergeResultPreview from "@/components/Merge/MergeResultPreview.vue";
import OrgMergeModal from "@/components/Merge/OrgMergeModal.vue";

export default {
  name: "SchoolMerge",
  components: {MergeObjectDisplay, UserHierarchyList, MergeResultPreview, OrgMergeModal},
  props: {
    idKeepingSchool: Number,
    idRemovingSchool: Number
  },
  setup(props) {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const keepingSchoolId = ref(-1);
    const removingSchoolId = ref(-1);

    const keepingSchoolDisplay = ref({});
    const removingSchoolDisplay = ref({});
    const previewDisplay = ref({});
    const keepingIds = ref({});
    const removingIds = ref({});
    const keysToIgnore = ref(['Code', 'id', 'Count']);
    const showMergeModal = ref(false);

    const keys = ref([]);

    onBeforeMount(() => {
      getSchools();
    })

    watch(() => props.idKeepingSchool, () => {
      if (props.idRemovingSchool !== -1) getSchools();
    })

    watch(() => props.idRemovingSchool, () => {
      if (props.idKeepingSchool !== -1) getSchools();
    })

    async function getSchools() {
      keepingSchoolDisplay.value = {};
      removingSchoolDisplay.value = {};
      previewDisplay.value = {};

      let school_one_tmp;
      await axios.get(getSchool(props.idKeepingSchool), {
        params: {
          showUserCount: true
        }
      }).then((result) => {
        school_one_tmp = result.data;
        previewDisplay.value = result.data;
        keepingIds.value = {
          idEsu: result.data.idEsu,
          idDistrict: result.data.idDistrict,
          districtName: result.data.schoolDistrict
        }
      });

      let school_two_tmp;
      await axios.get(getSchool(props.idRemovingSchool), {
        params: {
          showUserCount: true
        }
      }).then((result) => {
        school_two_tmp = result.data;
        removingIds.value = {
          idEsu: result.data.idEsu,
          idDistrict: result.data.idDistrict,
          districtName: result.data.schoolDistrict

        }
      });

      let diff_keys = Object.keys(school_one_tmp).filter(k => school_one_tmp[k] !== school_two_tmp[k]);
      keys.value = prepareMergeKeys(school_two_tmp, keysToIgnore.value);
      keepingSchoolDisplay.value = prepareMergeObject(school_one_tmp, keysToIgnore.value, diff_keys);
      removingSchoolDisplay.value = prepareMergeObject(school_two_tmp, keysToIgnore.value, diff_keys);
      removingSchoolId.value = props.idRemovingSchool;
      keepingSchoolId.value = props.idKeepingSchool;
    }

    function mergeHelper() {
      Swal.fire({
        title: 'Are You Sure?',
        text: 'This cannot be undone. Users will not be able to register under the old school.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true
      }).then((res) => {
        if (res.isConfirmed) {
          commitMerge();
        }
      })
    }

    async function commitMerge() {
      await axios.put(mergeSchools(props.idKeepingSchool, props.idRemovingSchool), previewDisplay.value, {
        headers: {
          "Authorization": "Bearer " + authToken.value
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: 'Successfully Merged',
            icon: 'success'
          })
        }
      }).finally(() => {
        router.push({name: 'ViewSchool', params: {idSchool: props.idKeepingSchool}})
      })
    }

    const showSchools = computed(() => {
      return !isEmpty(keepingSchoolDisplay.value) && !isEmpty(removingSchoolDisplay.value);
    })

    function updateSubmitObject(event) {
      previewDisplay.value[event.key] = event.value;

      if (event.key === 'schoolDistrict') {
        previewDisplay.value.idDistrict = event.value !== keepingIds.value.districtName ?
            keepingIds.value.idDistrict : removingIds.value.idDistrict;
      }

    }

    return {
      keepingSchoolDisplay,
      keepingSchoolId,
      removingSchoolDisplay,
      removingSchoolId,
      previewDisplay,
      keys,
      showSchools,
      keysToIgnore,
      showMergeModal,
      mergeHelper,
      updateSubmitObject,
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  /*max-height: 800px;*/
  /*!*height: 250px;*!*/
  /*width: 250px;*/
  /*border-radius: 0;*/
  padding: 0;
  /*border: 1px solid rgb(0, 0, 0);*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*padding: 1rem 1rem;*/

}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}

</style>
import {API_URL} from "../../../Constants";

export const getSchool = (idSchool) => {
    return `${API_URL}/school/displayById?_idSchool=${idSchool}`;
}

export const deleteSchool = (idSchool) => {
    return `${API_URL}/school/delete/${idSchool}`;
}

export const mergeSchools = (idKeepingSchool, idRemovingSchool) => {
    return `${API_URL}/school/merge/keeping/${idKeepingSchool}/removing/${idRemovingSchool}`;
}

export const searchSchools = () => {
    return `${API_URL}/school/search`
}

export const schoolByDistrict = () => {
    return `${API_URL}/school/district`
}
<template>
  <div class="card"  style="border: 3px solid grey;">
    <div class="card-header">
    <h3 style="color: black;">Result Preview</h3></div>
    <div class="card-body" >
      <table style="margin: 10px; table-layout: fixed;">
        <thead>
        <tr>
          <th style="color: black;">Field Name</th>
          <th style="color: black">Content</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in previewDisplay" :key="key">
          <td v-if="showKeyVal(key)" style="color: black">{{ displayKeys[key] }}</td>
          <td v-if="showKeyVal(key)" style="color: black">{{ val }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <button class="bi-link-45deg button btn-primary" @click="$emit('mergeClicked')">Merge</button>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "MergeResultPreview",
  props: {
    previewDisplay: Object,
    displayKeys: Array,
    ignoreKeys: Array
  },
  setup(props) {
    const showKeyVal = (keyVal) => {
      for (let i = 0; i < props.ignoreKeys.length; i++) {
        if (keyVal.includes(props.ignoreKeys[i])) return false;
      }
      return true;
    }

    return {
      showKeyVal
    }
  }
}
</script>

<style scoped>
table {
  border: 1px solid rgb(0, 0, 0);
}
th {
  border: 1px solid rgb(0, 0, 0);
}

td {
  border: 1px solid rgb(0, 0, 0);

  /*overflow-x: scroll;*/

}

tr {
  border: 1px solid rgb(0, 0, 0);
}

.card {
  display: flex;
  max-width: 400px;
  /*margin-left: 10px;*/
  margin-right: 10px;
  /*margin-bottom: 10px;*/
  /*max-height: 800px;*/
  /*!*height: 250px;*!*/
  /*width: 250px;*/
  /*border-radius: 0;*/
  padding: 0;
  /*border: 1px solid rgb(0, 0, 0);*/
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*padding: 1rem 1rem;*/

}

.card-header {
  /*padding: 4% 8%;*/
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgb(0, 0, 0);
  height: 75px;
  justify-content: center;
  align-items: center;
  display: flex;
  /*text-overflow: ellipsis;*/
}
</style>
export const prepareMergeObject = (objToMerge, keysToIgnore, diffKeys) => {
    let ret_obj = {};
    for (const prop in objToMerge) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");
        if (addVal(prop, keysToIgnore)) {
            ret_obj[prop] = {
                value: objToMerge[prop],
                highlight: diffKeys.includes(prop) && (!prop.includes('Date') && !prop.includes('date')),
                label: result
            }
        }
    }
    return ret_obj;
}

export const prepareMergeKeys = (objToMerge, keysToIgnore) => {
    let keys = [];
    for (const prop in objToMerge) {
        const split_text = prop.replace(/([A-Z])/g, " $1");
        let result = split_text.charAt(0).toUpperCase() + split_text.slice(1);
        if (prop.includes('country')) result = result.replace(" Name", "");
        if (prop.includes('state')) result = result.replace(" Name", "");

        if (addVal(prop, keysToIgnore)) {
            keys[prop] = result;
        }
    }
    return keys;
}

const addVal = (prop, keysToIgnore) => {
    for (let i = 0; i < keysToIgnore.length; i++) {
        if (prop.includes(keysToIgnore[i])) return false;
    }
    return true;
}